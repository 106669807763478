import React from 'react'
import { css } from '../../../../styled-system/css'

// @ts-expect-error
import Logo from "../../../images/logo_w.svg"

import FloatingObjects from './FloatingObjects'
import ParticlesApp from './Praticles'
import FooterMenus from './FooterMenus'
import Galaxy from './Galaxy'
import { getPath } from '../../../utils/getPath'

const ComputerLayout = () => {
    const imageData = getPath('index/bg.webp');
    const imageUrl = imageData?.images?.fallback?.src || '';
    return (
        <div
            className={css({
                height: '100vh',
                width: '100%',
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between",
                overflow: "hidden",
            })}
            style={{
                backgroundImage: "url('" + imageUrl + "')",
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
            }}>
            <div className={css({
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1vh",
                w: "100%",
            })}>
                <img className={css({
                    w: "10%"
                })} src={Logo} alt="" />
            </div>
            <div className={css({
                position: "absolute",
                zIndex: 0,
                top: "0",
                left: "0",
                w: "100vw",
                h: "100vh",
                overflow: "hidden",
            })}>
                <Galaxy />
            </div>
            <FooterMenus />
            <div className={css({
                position: "absolute",
                top: "0",
                left: "0",
                w: "100vw",
                h: "70vh",
                zIndex: 0,
            })}>
                <ParticlesApp />
            </div>
            <FloatingObjects />
        </div>
    );
}

export default ComputerLayout;