import React from 'react'
import { css } from '../../../../styled-system/css'
import Menus from './Menus'

// @ts-expect-error
import Logo from "../../../images/logo_r_w.svg"

const MobileLayout = () => {
    return (
        <div
            className={css({
                height: 'auto',
                width: '100%',
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between",
                background: "linear-gradient(135deg, #0a1e35 20%, #102a4a 60%, #1b3d5e 100%)",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                _before: {
                    content: "''",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "radial-gradient(circle, rgba(255, 255, 255, 0.15) 30%, rgba(0, 0, 0, 0.5) 80%)",
                    pointerEvents: "none",
                    zIndex: 0,
                    opacity: 0.6,
                }
            })}>
            <div className={css({
                zIndex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                w: "100%",
                padding: "1rem",
                gap: "0.25rem"
            })}>
                <img className={css({
                    w: "50%",
                    marginBottom: "0.5rem"
                })} src={Logo} alt="" />
                <h1 className={css({
                    color: "white",
                    fontFamily: "'Exo 2' !",
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    textAlign: "center"
                })}>
                    Bienvenue chez Digit Universe !
                </h1>
                <h2 className={css({
                    color: "white",
                    fontFamily: "'Exo 2' !",
                    fontSize: "0.75rem",
                    fontWeight: "300",
                    textAlign: "center"
                })}>
                    Experts en recrutement IT & Web et photographie à Lille, nous proposons des services personnalisés pour tous vos besoins professionnels et personnels.
                </h2>
            </div>

            <Menus />
        </div>
    );
}

export default MobileLayout;